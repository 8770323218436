.navigation {
  position: relative;
  width: 100%;
  height: 70px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
z-index: 99; 
  position: fixed;
  bottom: -10px;
  display: none;

  ul {
    display: flex;
    width: 100%;
 
    background-color: #e90b5a;
    justify-content: space-around;

    li {
      position: relative;
      list-style-type: none;
      transition: 0.5s;
      width: 20%;
      height: 10vmax;
      z-index: 1;
      text-align: center;

      a {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 20;
        text-align: center;
        font-weight: 500;
        z-index: 10000;

        .icon {
          position: relative;
          //   display: block;
          line-height: 75px;
          font-size: 1.5em;
          text-align: center;
          transition: 0.5s;
          color: #fff;
          transform: translateY(10px);
        }

        .text {
          position: absolute;
          color: #fff;
          font-weight: 400;
          font-size: 0.8rem;
          letter-spacing: 0.05em;
          transition: 0.5s;
          opacity: 1;
          top: 1rem;
          transform: translateY(20px);
        }
      }

      .active {
        .icon {
          transform: translateY(-15px);
        }

        .text {
          opacity: 1;
          transform: translateY(15px);
        }

        &:before {
          content: "";
          position: absolute;
          width: 60px;
          height: 60px;
          background-color: #e90b5a;
          bottom: 5px;
          border-radius: 50%;
          border: 5px solid #fff;
          transition: 0.5s;
        }
      }
    }
  }
}

@media screen and (max-width: 668px) {
  .navigation {
    display: block;
  }
}

@media screen and (max-width: 338px) {
  .navigation {
    ul {
      li {
        a {
          .text {
            position: absolute;
            color: #fff;
            font-weight: 400;
            font-size: 0.7rem;
            letter-spacing: 0.05em;
            transition: 0.5s;
            opacity: 1;
            top: 1rem;
            transform: translateY(20px);
          }
        }
      }
    }
  }
}
