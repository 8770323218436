.error {
    background-color: rgb(255, 181, 181);
    color: red;
    padding: .5vmax 1vmax;
    display: block;
    text-transform: uppercase;
    font-size: .81vmax;
    width: fit-content;
    margin: .5rem 0;
}

.project-logo {
    width: 110px;
    height: 65px;
}

.primary-btn { 
    border-radius: 5px;
    font-weight: 500;
    line-height: 1;
    cursor: pointer;
    text-transform: capitalize;
    background-color: #e90b5a;
    color: #fff!important;
    -webkit-transition: all .1s ease-in-out;
    -o-transition: all ease-in-out .1s;
    transition: all .1s ease-in-out;
    border: none; 
    font-size: 16px;
    // height: 48px;
    padding: 14px 24px;
    min-width: 148px;
}

.primary-btn:hover {
    background-color: #da054f;
}

.basic-link-btn {
    background: none repeat scroll 0 0 transparent;
    border: 2px solid black;
    box-shadow: none;
    color: #222d3a;
    font: 700 11pt Helvetica,Arial,sans-serif!important;
    padding: 8px 16px;
    cursor: pointer;
    box-sizing: content-box!important;  
    margin: 1vmax;
    text-transform: uppercase;
    transition: all 0.5s;
}

.basic-link-btn:hover {
    color: #f47d39;
    border: solid 2px #f47d39;
}

.basic-trans-btn {
    display: block; 
    padding: 10px 28px;
    /* display: block; */
    // width: 100%;
    color: #e90b5a;
    background-color: #fff;
    border: 1px solid #e90b5a;
    -webkit-transition: all .1s ease-in-out;
    -o-transition: all ease-in-out .1s;
    transition: all .5s ease-in-out; 
    text-align: center; 
    // font-weight: bold;
    border-radius: 4px;
    // min-width: 148px;
    margin-top: 1.5vmax; 
    cursor: pointer;
    // width: 15vmax;
}

.basic-trans-btn:hover {
    background-color: pink;
    border: 1px solid pink;
     color: #cc0058;
}


.updateBtn {
    border: none;
    background-color: #e90b5a;
    color: white;
    font: 500 1vmax "Roboto";
    width: 100%;
    cursor: pointer;
    padding: 1rem;
    transition: all 0.5s;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.213);
    font-family: sans-serif;
    margin-top: 1vmax;
    text-transform: uppercase;
    transition: all .5s ease-in-out; 
}

.updateBtn:hover {
    background-color: pink; 
     color: #cc0058;
}

.mt-1 {
    margin-top: .5rem;
}


.scrollTop {
    position: fixed; 
    font-size: 2rem;
    width: 35px;
    bottom: 70px;
    right: 1rem;
    display: flex;
    align-items: center;
    height: 35px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.4s;
    transition: opacity 0.5s; 
    color: #FF4842;
    background-color: #FF4842;
    color: #fff;
    padding: .2rem; 
  } 
  
  .greenColor {
    color: green;
  }
  
  .redColor {
    color: red;
  }
  .center {
    text-align: center;
  }
   
 .readOnly {
    cursor: no-drop;
 } 

 .mb-4{
    margin-bottom: 4rem;
 }

 .mb-3{
    margin-bottom: 3rem;
 }

 .mb-2{
    margin-bottom: 2rem;
 }

 .mb-1{
    margin-bottom: 1rem;
 }

 .mt-4{
    margin-top: 4rem;
 }

 .mt-3{
    margin-top: 3rem;
 }

 .mt-2{
    margin-top: 2rem;
 }

 .mt-1{
    margin-top: 1rem;
 }

@media screen and (max-width: 768px){
    .error {
        font-size: 1.5vmax;
    }

     .basic-trans-btn {
        width: 25vmax;
    }
    .updateBtn {
        font-size: 1rem; 
    }
}