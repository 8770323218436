.add-product-form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3vmax;
}

.redbg > div {
  text-align: center;
  border: 2px solid rgb(204, 2, 2) !important;
  color: rgb(204, 2, 2);
  padding: 2px 4px;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: bold !important;
}

.greenBg > div {
  text-align: center;
  border: 2px solid green !important;
  color: green;
  padding: 2px 4px;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: bold !important;
}
.instock > div {
  text-align: center;
  border: 2px solid green !important;
  padding: 4px 0px;
  border-radius: 20px;
  width: 4rem;
  font-weight: bold !important;
}

.outofstock > div {
  text-align: center;
  border: 2px solid rgb(204, 2, 2) !important;
  padding: 4px 0px;
  border-radius: 20px;
  color: rgb(204, 2, 2);
  width: 4rem;
  font-weight: bold !important;
}

.ql-container.ql-snow {
  min-height: 15rem !important;
  max-height: 100%;
}

.validPayment {
  color: rgb(27, 94, 32);
  border: 1px solid rgb(46, 125, 50);
  border-radius: 16px;
  padding: 8px 12px;
  height: 24px;
  white-space: nowrap;
  cursor: default;
  font-size: 0.9125rem;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  vertical-align: middle;
  box-sizing: border-box;
  background-color: transparent;
  text-transform: uppercase;
}

.invalidPayment {
  color: rgb(198, 40, 40);
  border: 1px solid rgb(211, 47, 47);
  max-width: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  border-radius: 16px;
  padding: 8px 10px;
  height: 24px;
  font-size: 0.8125rem;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  height: 24px;
  border-radius: 16px;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: default;
  vertical-align: middle;
  box-sizing: border-box;
  background-color: transparent;
  text-transform: uppercase;
}

.add-product-form > form {
  width: 100%;
}

#createProductFormImage {
  width: 100%;
  display: flex;
  overflow: auto;
}

#createProductFormImage > img {
  width: 4rem;
  height: 4rem;
  margin: 1rem 1rem 1rem 0;
}

.MuiDataGrid-columnHeader {
  background-color: #e90b5a;
  padding: 1rem !important;
  text-transform: uppercase;
}

.MuiDataGrid-columnHeader .MuiCheckbox-colorPrimary,
.MuiDataGrid-columnHeaderCheckbox {
  color: #fff !important;
  width: 100%;
}

.MuiDataGrid-columnHeader div,
.MuiDataGrid-menuIconButton {
  color: white !important;
}

.MuiDataGrid-iconSeparator {
  display: none !important;
}

.user-account-info {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.order-item-container {
  height: min-content;
  max-height: 100%;
  overflow-y: auto;
}

.order-item-container > div {
  display: flex;
  font: 400 1rem "Roboto";
  align-items: flex-start;
  justify-content: space-around;
  margin: 2vmax 0;
}

.order-item-container > div > img {
  width: 80px;
  height: 80px;
}

.order-item-container > div > a {
  color: #575757;
  margin: 0 2vmax;
  width: 55%;
  text-decoration: none;
}

.order-item-container > div > span {
  font: 100 1vmax "Roboto";
  color: #5e5e5e;
}

.backBtn {
  background-color: #e90b5a;
  color: white;
  padding: 1rem 2rem;
  border: none;
  cursor: pointer;
  font: 400 1rem "Roboto";
  transition: all 0.5s;
}

.backBtn:hover {
  background-color: #e90b5a;
}

@media screen and (max-width: 768px) {
  .user-account-info {
    flex-direction: column;
    justify-content: center;
  }

  .account-info {
    width: 100%;
    border-right: none;
  }

  .order-info {
    margin-top: 1rem;
    width: 100%;
    border-right: none;
  }

  .order-item-container > div {
    font: 400 3vw "Roboto";
    margin: 4vw 0;
  }

  .order-item-container > div > img {
    width: 10vw;
  }

  .order-item-container > div > a {
    color: #575757;
    margin: 0;
    width: 40%;
  }

  .order-item-container > div > span {
    font: 100 3vw "Roboto";
  }
}
