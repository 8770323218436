@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;1,300;1,400&display=swap");

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "Roboto", sans-serif;
}
.paddingX2 {
  padding: 2vmax 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
a {
  text-decoration: none;
}

body {
  overflow-x: hidden;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  /* border-radius: 10px; */
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  /* border-radius: 10px; */
  background-color: #fff;
  background-image: -webkit-linear-gradient(
    top,
    #ffa4b3 0%,
    #fd8597 40%,
    #e0517c 70%,
    #e90b5a 100%
  );
}

a {
  text-decoration: none;
  color: #000;
}

button[disabled] {
  cursor: no-drop;
}

input[type="disabled"] {
  cursor: no-drop;
}