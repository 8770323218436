.home-page-loader {
    min-height: 100vh;
    /* background-color:  #0d0d0d; */
    color: #0d0d0d;
    display: flex;
    justify-content: center;
    align-items: center;
}
.spinner-box{
    width: 430px;
    height: 430px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle-border{
    width: 250px;
    height: 250px;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(0deg, rgba(245, 242, 242, 0) 31%, #062e83 49% , #4566ad 50%);
    animation: spin 2s linear infinite;
}
.circle-core{
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 50%;
}
em{
    position: absolute;
    font-size: 22px;
}
@keyframes spin {
    0%{
        transform: rotate(0deg);
    }
   100%{
        transform: rotate(360deg);
    }
}

.load{
    border: 1px solid #ECF0F1;
    padding: 5px;
    position:absolute;
    top:50%;
    left:50%;
    transform-origin: center center;
    transform:translate(-50%, -50%) rotate(135deg);
    /*change these sizes to fit into your project*/
    width:50px;
    height:50px;
    z-index: 999;
    
  }
  .load hr{
    border:0;
    margin:0;
    width:40%;
    height:40%;
    position:absolute;
    border-radius:50%;
    animation:spin 2s ease infinite;
  }
  
  .load :first-child{background:#19A68C;animation-delay:-1.5s}
  .load :nth-child(2){background:#F63D3A;animation-delay:-1s}
  .load :nth-child(3){background:#FDA543;animation-delay:-0.5s}
  .load :last-child{background:#193B48}
  
  @keyframes spin{
    0%,100%{transform:translateX(200%)}
    25%{transform:translateY(200%)}
    50%{transform:translateX(0)}
    75%{transform:translateY(0)}
  }